<template>
    <v-navigation-drawer
            fixed
            :clipped="$vuetify.breakpoint.mdAndUp"
            app
            :value="drawer"
            @input="$emit('toggleDrawer', $event)"
            :width="300"
    >
            <v-list dense class="mt-4" expand>
                <navigation-list-template :items="studMenuItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
    </v-navigation-drawer>
</template>
<script>
    import HttpService from "../../../HttpService";
    import NavigationListTemplate from '../../common/NavigationListTemplate';
    import {mapMutations} from "vuex";
    import isArray from "lodash/isArray";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        mounted() {
            HttpService.getInstance()
                .get("navigationStudent/getSubjectsByStudent")
                .then(res => {
                    let subjects = [];

                    if (isArray(res)) {
                        subjects = res.map(subject => {
                            const specs = subject.specialities.map(s => s.nameShortUk);
                            
                            return {
                                id: subject.id,
                                name: `${subject.nameUk} (${specs.join(", ")})`,
                                route: {
                                    params: {
                                        subject: subject.id,
                                    }
                                },
                            };
                        });
                    }

                    this.studMenuItemsRaw.forEach(s => {
                       if (s.nameConst === "marks") {
                           s.children = [...subjects].map(s => {
                               const subj = {
                                   ...s,
                                   route: {
                                       ...s.route,
                                       name: "studentMarks"
                                   }
                               };
                               
                               return subj;
                           });
                       }

                        if (s.nameConst === "reworks") {
                            s.children = [...subjects].map(s => {
                                const subj = {
                                    ...s,
                                    route: {
                                        ...s.route,
                                        name: "consultationsAndReworks"
                                    }
                                };
                                
                                return subj;
                            });
                        }
                    });
                    
                    this.studMenuItems = this.remapItems(this.studMenuItemsRaw);
                });
            
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);
                
                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        
                        item.children = item.children.map(ch => {
                            if (this.$route.params.subject && ch.id.toString() === this.$route.params.subject.toString()) {
                                
                                this.setTopNavigationItem(ch);
                            }
                            
                            return ch;
                        });
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            }
        },
        data: () => ({
            studMenuItems: [],
            studMenuItemsRaw: [
                {icon: 'border_color', nameConst: 'marks', name: 'Оцінки'},
                {divider: true},
                {icon: 'insert_invitation', nameConst: 'calendar', name: 'Календар оцінок', route: {name: "studentMarksCalendar"}},
                {icon: 'format_align_left', nameConst: 'marksMonthly', name: 'Оцінки помісячно', route: {name: "studentMarksByMonth"}},
                {icon: 'contacts', nameConst: 'absents', name: 'Пропуски занять', route: {name: "absents"}},
                {icon: 'cached', nameConst: 'reworks', name: 'Консультації & відробітки'},
                {divider: true},
                {icon: 'history', nameConst: 'payments', name: 'Історія платежів', route: {name: "paymentHistory"}},
            ]
        })
    }
</script>