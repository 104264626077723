var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',{staticClass:"mb-4",attrs:{"xs12":""}},[_c('v-card',{staticClass:"px-4"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-flex',{staticClass:"subtitle-1",attrs:{"xs12":""}},[_vm._v("Графік відробітків та консультацій вказаний на наступні 14 днів. ")]),_c('v-flex',{staticClass:"subtitle-1",attrs:{"xs12":""}},[_vm._v("Кількість академчних заборгованостей з даної дисципліни: "),_c('strong',[_vm._v(_vm._s(_vm.countOfDebts)+" ("+_vm._s(_vm.sumOfAcademicHoursDebts)+" год.)")])])],1)],1)],1)],1)],1),_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" URL на Hangouts Meet та час проведення відробітків буде вказано лише при наявності заборгованостей з даної дисципліни та після внесення викладачем. ")]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-3 scrollable",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"hide-default-footer":"","loading":_vm.loading,"options":_vm.pagination,"footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.formatShortDate(item.date)))]}},{key:"item.time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.startTime ? _vm.moment(item.startTime).format("HH:mm") : "--:--")+" - "+_vm._s(item.startTime ? _vm.moment(item.endTime).format("HH:mm") : "--:--")+" ")]}},{key:"item.reworkUrl",fn:function(ref){
        var item = ref.item;
return [(item.reworkUrl)?_c('div',[_c('a',{attrs:{"target":"_blank","href":item.reworkUrl}},[_vm._v("Перейти")])]):_vm._e()]}},{key:"item.department",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.department.nameUk))]}},{key:"item.professor",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.professor.academicPosition.nameShortUk)+" "+_vm._s(((item.professor.surname) + " " + (item.professor.name.charAt(0)) + ". " + (item.professor.patronymic.charAt(0)) + "."))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(((item.professor.surname) + " " + (item.professor.name) + " " + (item.professor.patronymic))))])])]}},{key:"item.appointment",fn:function(ref){
        var item = ref.item;
return [(!item.couldMakeAppointment)?_c('span',{staticClass:"grey--text"},[_vm._v("Запис недоступний")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }