import { render, staticRenderFns } from "./StudentMarksCalendarSelector.vue?vue&type=template&id=0950027e&scoped=true&"
import script from "./StudentMarksCalendarSelector.vue?vue&type=script&lang=js&"
export * from "./StudentMarksCalendarSelector.vue?vue&type=script&lang=js&"
import style0 from "./StudentMarksCalendarSelector.vue?vue&type=style&index=0&id=0950027e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0950027e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardText,VLayout})
