var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3 scrollable absents-data-table",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"show-select":"","footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event},"input":_vm.onInputGrid},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(props){return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"primary","hide-details":"","value":_vm.selectAllCheckbox.value,"indeterminate":_vm.selectAllCheckbox.indeterminate},on:{"change":function($event){return _vm.checkAllChanged($event)}}})]}},{key:"item.data-table-select",fn:function(props){return [(_vm.checkIfAbsencePaymentCanBeDone(props.item))?_c('v-checkbox',{staticClass:"mt-0",attrs:{"input-value":_vm.checkItemChecked(props.item),"color":"primary","hide-details":""},on:{"change":function($event){return _vm.checkItemChanged($event, props.item)}}}):_vm._e()]}},{key:"item.payment",fn:function(ref){
        var item = ref.item;
return [_c('payment-status',{attrs:{"topic-absence":item.topicAbsence},on:{"update":function($event){return _vm.$emit('update')}}})]}},{key:"item.attachment",fn:function(ref){
        var item = ref.item;
return [(_vm.isEmpty(item.attachmentFileName) && item.value === _vm.markValuesEnum.absence)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('addNewAttachment', item)}}},on),[_c('v-icon',[_vm._v("vertical_align_top")])],1)]}}],null,true)},[_c('span',[_vm._v("Завантажити додаток (довідка, квитанція)")])])],1):(!_vm.isEmpty(item.attachmentFileName))?_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('getAttachment', item)}}},on),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v("Переглянути додаток (довідка, квитанція)")])])],1):_c('div',{staticClass:"d-flex justify-center"},[_vm._v("-")]),(item.attachmentIsRejected)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("error")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.attachmentRejectionText))])])],1):_vm._e()]}},{key:"item.classDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatShortDate(item.classDate))+" ")]}},{key:"item.reworkPrice",fn:function(ref){
        var item = ref.item;
return [(item.value === 0)?_c('v-container',[_vm._v(_vm._s(((item.reworkPrice) + " " + (_vm.getCurrency()))))]):_c('v-container',[_vm._v("-")])]}},{key:"item.absentType",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.value === _vm.markValuesEnum.absence)?_c('span',{staticClass:"pr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"large":"","color":"red darken-2"}},on),[_vm._v("font_download")])]}}],null,true)},[_c('span',[_vm._v("Пропущене заняття")])])],1):_vm._e(),(item.value === _vm.markValuesEnum.unsatisfactory)?_c('span',{staticClass:"pr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"large":"","color":"red darken-2"}},on),[_vm._v("filter_2")])]}}],null,true)},[_c('span',[_vm._v("Незараховане заняття")])])],1):_vm._e(),_c('div',[(item.topicAbsence)?_c('admission-details',{staticClass:"absence-icon",attrs:{"topicAbsence":item.topicAbsence}}):_vm._e()],1)])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }