<template>
    <v-container grid-list-md>
        <v-flex xs12>
            <v-card>
                <v-container fluid fill-height>
                    <v-layout row wrap justify-center>
                        <v-flex xs12 md6>
                            <v-flex xs12 class="subtitle-1">Загальна кількість тем: {{activeModule.topicsAllCount}}
                            </v-flex>
                            <v-flex xs12 class="subtitle-1">Кількість оцінювальних тем:
                                {{activeModule.topicsEvaluableCount}}
                            </v-flex>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-layout row wrap justify-center="">
                                <v-flex xs4>
                                    <div :class="`${getMarkClass({value: 5})} mark-student-cell-preview`">5</div>
                                    <div class="d-flex subtitle-1 justify-center">ECTS: {{activeModule.ects5}}</div>
                                </v-flex>
                                <v-flex xs4>
                                    <div :class="`${getMarkClass({value: 4})} mark-student-cell-preview`">4</div>
                                    <div class="d-flex subtitle-1 justify-center">ECTS: {{activeModule.ects4}}</div>
                                </v-flex>
                                <v-flex xs4>
                                    <div :class="`${getMarkClass({value: 3})} mark-student-cell-preview`">3</div>
                                    <div class="d-flex subtitle-1 justify-center">ECTS: {{activeModule.ects3}}</div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card>
        </v-flex>
        <v-flex xs12>
            <v-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :options.sync="pagination"
                    class="elevation-3 mt-4 scrollable"
                    hide-default-footer
                    no-data-text="Немає оцінок"
            >
                <template v-slot:item.topicName="{item}">{{ `${item.orderNumber}. ${item.nameUk}` }}</template>
                <template v-slot:item.evaluationMode="{item}">
                    {{getEvaluationModeType(item.evaluationMode)}}
                </template>
                <template v-slot:item.markDate="{item}">{{ (item.mark !== null) ? formatMarkDate(item.mark.classDate) :
                    "" }}
                </template>
                <template v-slot:item.academicHours="{item}">{{ item.academicHours }}</template>
                <template v-slot:item.markValue="{item}">
                    <div :class="`${getMarkClass(item.mark)} mark-student-cell`">
                        <mark-inner :mark="item.mark"></mark-inner>
                    </div>
                </template>
                <template v-slot:item.markEcts="{item}">{{ item.mark !== null ? item.mark.valueEcts : "" }}</template>
                <template v-slot:item.professor="{item}">
                    {{ item.mark !== null
                    ? `${item.mark.professor.surname}&nbsp;${item.mark.professor.name.charAt(0)}.&nbsp;${item.mark.professor.patronymic.charAt(0)}.`
                    : ""
                    }}
                </template>
            </v-data-table>
        </v-flex>
    </v-container>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import {formatMarkDate} from "../../../helpers/dateHelper";
    import {getMarkClass} from "../../../helpers/markHelper";
    import MarkInner from "../../common/MarkInner";
    import topicEvaluationModeTypes from "../../../constants/topicEvaluationModeTypes";

    export default {
        props: {
            activeModule: {
                type: Object,
                default: () => ({id: null}),
            }
        },
        components: {
            MarkInner,
        },
        data: () => ({
            headers: [
                {text: 'Тема', value: 'topicName'},
                {text: 'Тип заняття', value: 'evaluationMode'},
                {text: 'Дата', value: 'markDate'},
                {text: 'К-сть академічних годин', value: 'academicHours'},
                {text: 'Оцінка', value: 'markValue', width: "100px"},
                {text: 'Оцінка ECTS', value: 'markEcts', sortable: false,},
                {text: 'Викладач', value: 'professor', sortable: false, width: "180px"},
            ],
            items: [],
            loading: false,
            pagination: {
                itemsPerPage: -1,
            },
            formatMarkDate,
            getMarkClass,
        }),
        mounted() {
            this.$ga.event('student', 'viewMarks', 'subjectId', this.$route.params.subject);
        },
        watch: {
            pagination: {
                handler() {
                    this.getItems(objectQueryStringify(this.pagination));
                },
                deep: true
            },
            "activeModule.id": function (newModule, oldModule) {
                if (newModule !== undefined && newModule !== oldModule) {
                    this.getItems(objectQueryStringify(this.pagination));
                }
            }
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;
                HttpService.getInstance()
                    .get(`studentMarks/getByModule/${this.activeModule.id}?${queryParams}`)
                    .then(resp => {
                        this.loading = false;
                        if (resp) {
                            this.items = resp.items;
                        }
                    });
            },
            getEvaluationModeType(type) {
                const foundEvaluationType = topicEvaluationModeTypes.find(i => i.id === type);

                if (foundEvaluationType) {
                    return foundEvaluationType.nameUk;
                }

                return "";
            }
        }
    }
</script>
<style lang="scss" scoped>
    .mark {
        text-align: center !important;
        font-size: 18px;
    }

    .mark-student-cell {
        display: flex;
        height: 100%;
        padding: 10px;
        align-items: center;
        justify-content: center;
    }

    .mark-student-cell-preview {
        min-height: 2em;
        max-height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>