<template>
    <div>
        <v-flex xs12 class="mb-4">
            <v-card class="px-4">
                <v-container fluid fill-height>
                    <v-layout row wrap justify-center>
                        <v-flex xs12>
                            <v-flex xs12 class="subtitle-1">Графік відробітків та консультацій вказаний на наступні 14 днів.
                            </v-flex>
                            <v-flex xs12 class="subtitle-1">Кількість академчних заборгованостей з даної дисципліни: 
                                <strong>{{countOfDebts}} ({{sumOfAcademicHoursDebts}} год.)</strong>
                            </v-flex>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card>
        </v-flex>
        <v-alert type="info">
            URL на Hangouts Meet та час проведення відробітків буде вказано лише при наявності заборгованостей з даної дисципліни
            та після внесення викладачем.
        </v-alert>
        <v-flex xs12>
            <v-data-table
                    :headers="headers"
                    :items="items"
                    :server-items-length="total"
                    hide-default-footer
                    :loading="loading"
                    :options.sync="pagination"
                    class="elevation-3 scrollable"
                    :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
            >
                <template v-slot:item.date="{item}">{{ formatShortDate(item.date) }}</template>
                <template v-slot:item.time="{item}">
                    {{ item.startTime ? moment(item.startTime).format("HH:mm") : "--:--"}}  - 
                    {{ item.startTime ? moment(item.endTime).format("HH:mm") : "--:--"}}
                </template>
                <template v-slot:item.reworkUrl="{item}">
                    <div v-if="item.reworkUrl">
                        <a target="_blank" :href="item.reworkUrl">Перейти</a>
                    </div>
                </template>
                <template v-slot:item.department="{item}">{{item.department.nameUk}}</template>
                <template v-slot:item.professor="{item}">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <span v-on="on">
                                {{item.professor.academicPosition.nameShortUk}}
                                {{`${item.professor.surname} ${item.professor.name.charAt(0)}.
                                ${item.professor.patronymic.charAt(0)}.`}}
                            </span>
                        </template>
                        <span>{{`${item.professor.surname} ${item.professor.name} ${item.professor.patronymic}`}}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.appointment="{item}">
                    <span class="grey--text" v-if="!item.couldMakeAppointment">Запис недоступний</span>
                </template>
            </v-data-table>
        </v-flex>
    </div>
</template>
<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    import paymentStatuses, {paymentStatusesEnum} from "../../../constants/paymentStatuses";
    import moment from "moment";

    export default {
        props: {
            items: {
                type: Array,
            },
            countOfDebts: {
                type: Number,
            },
            sumOfAcademicHoursDebts: {
                type: Number,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Дата', value: 'date', sortable: false},
                {text: 'Час', value: 'time', sortable: false},
                {text: 'URL', value: 'reworkUrl', sortable: false},
                {text: 'Кафедра', value: 'department', sortable: false},
                {text: 'Викладач', value: 'professor', sortable: false},
                {text: 'Запис на відробітки', value: 'appointment', sortable: false, width: "200px"},
            ],
            formatShortDate,
            moment,
        }),
        methods: {
            getPaymentStatus(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.nameUk;
            },
            getPaymentColor(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.color;
            }
        }
    }
</script>
<style lang="scss">
    .order-id-wrapper {
        word-break: break-all;
    }
</style>