<template>
    <div>
        <span v-if="topicAbsence != null && topicAbsence.payment != null">
            <v-chip :color="getPaymentColor(topicAbsence.payment.paymentStatus)" text-color="white">
                {{getPaymentStatus(topicAbsence.payment.paymentStatus)}}
            </v-chip>
            <v-btn v-if="topicAbsence.payment.paymentStatus !== paymentStatusesEnum.finished"
                   small
                   color="primary"
                   class="mt-2"
                   @click="checkStatus(topicAbsence.payment.id)"
                   :loading="loading"
            >
                Перевірити
            </v-btn>
        </span>
        <span v-else>-</span>
    </div>
</template>

<script>
    import paymentStatuses, {paymentStatusesEnum} from "../../../constants/paymentStatuses";
    import HttpService from "../../../HttpService";

    export default {
        props: {
            topicAbsence: Object,
        },
        data: () => ({
            paymentStatuses,
            paymentStatusesEnum,
            loading: false,
        }),
        methods: {
            checkStatus(paymentId) {
                this.loading = true;
                
                HttpService.getInstance()
                    .get(`payment/checkPaymentStatus/${paymentId}`)
                    .then(() => {
                        this.$emit("update");
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            getPaymentStatus(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.nameUk;
            },
            getPaymentColor(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.color;
            }
        }
    }
</script>