<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex>
                <v-card>
                    <v-container v-if="!isSelectedSubject" grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть дисципліну
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                
                <marks-wrapper :key="$route.fullPath" v-if="isSelectedSubject"></marks-wrapper>
            </v-flex>
        </v-layout>
    </v-container>           
</template>

<script>
    import HttpService from "../../HttpService";
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import MarksWrapper from "../../components/student/marks/MarksWrapper";
    import isArray from "lodash/isArray";

    export default {
        components: {
            MarksWrapper,
            NavigationListTemplate,
        },
        data: () => ({
            subjects: [],
            activeSub: null,
            isSelectedSubject: false,
        }),
        watch: {
            $route: function(route, prevRoute) {
                if (route.params.subject !== prevRoute.params.subject) {
                    this.isSelectedSubject = route.params.subject !== undefined;
                }
            },
        },
        mounted() {
            this.isSelectedSubject = this.$route.params.subject !== undefined;

            HttpService.getInstance()
                .get("subjects/getAll")
                .then(res => {
                    if (isArray(res)) {
                        this.subjects = res.map(subject => {
                            subject.name = subject.nameUk;
                            subject.route = {
                                name: 'studentMarks',
                                params: {
                                    subject: subject.id,
                                }
                            };

                            return subject;
                        });
                    }
                });
        },
        
    }
</script>
<style lang="scss" scoped>
    .mark-active-tab {
        background: #0D47A1;
        opacity: 1 !important;
    }
</style>