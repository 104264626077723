<template>
    <v-card>
        <v-card-title class="title">Завантаження зображення</v-card-title>
        <v-card-text>
            Тут Ви можете завантажити довідку або квитанцію оплати, які будуть розглянуті деканатом в робочий час.

            <image-uploader
                    class="mt-3"
                    v-model="attachmentImageUrl"
                    :image-endpoint="`${$config.apiEndpoint}/file/get`"
                    :type="fileUploadEnum.reworkAdmissions">

            </image-uploader>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="cancelUpload"
                    :disabled="loadingDelete"
            >Відміна
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="saveUploadedItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete"
            >Зберегти
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {fileUploadEnum} from "../../../constants/_internal/fileUploadEnum";
    import ImageUploader from "../../common/ImageUploader";

    export default {
        components: {
            ImageUploader,
        },
        data: () => {
            return {
                loadingDelete: false,
                fileUploadEnum,
                attachmentImageUrl: null,
            };
        },
        props: {
            item: Object,
        },
        methods: {
            saveUploadedItem() {
                this.loadingDelete = true;

                HttpService.getInstance()
                    .put(`studentRework/updateAttachment/${this.item.id}`, {attachmentImageUrl: this.attachmentImageUrl})
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("saved");
                    });
            },
            cancelUpload() {
                HttpService.getInstance()
                .delete(`${this.$config.apiEndpoint}/file/remove/reworkAdmission/${this.attachmentImageUrl}`)
                .then(() => {
                });
                
                
                this.$emit('shouldClose');
            }
        }
    }
</script>