<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3 mt-4 scrollable"
            hide-default-footer
            no-data-text="Немає лекцій"
    >
        <template v-slot:item="props">
            <tr>
                <td class="text-xs-left cursor-pointer">
                    {{ `${props.item.orderNumber}. ${props.item.nameUk}` }}
                </td>
                <td class="text-xs-left cursor-pointer">
                    {{ (props.item.mark !== null) ? formatDate(props.item.mark.classDate) : "" }}
                </td>
                <td :class="getMarkClass(props.item.mark)">
                    {{ props.item.mark !== null ? props.item.mark.value : "" }}
                </td>
                <td class="text-xs-left cursor-pointer">
                    {{
                    props.item.mark !== null
                    ? `${props.item.mark.professor.surname}
                    ${props.item.mark.professor.name.charAt(0)}.
                    ${props.item.mark.professor.patronymic.charAt(0)}.`
                    : ""
                    }}
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import moment from "moment";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";

    export default {
        props: {
            activeModule: {
                type: Object,
                default: () => ({id: null}),
            }
        },
        data: () => ({
            headers: [
                {text: 'Тема лекції', value: 'orderNumber'},
                {text: 'Дата', value: 'markDate'},
                {text: 'Відвідування', value: 'markValue'},
                {text: 'Лектор', value: 'professor', sortable: false,},
            ],
            items: [],
            loading: false,
            pagination: {
                rowsPerPage: -1,
            },
        }),
        watch: {
            pagination: {
                handler() {
                    this.getItems(objectQueryStringify(this.pagination));
                },
                deep: true
            },
            "activeModule.id": function (newModule, oldModule) {
                if (newModule !== undefined && newModule !== oldModule) {
                    this.getItems(objectQueryStringify(this.pagination));
                }
            }
        },
        methods: {
            getItems(queryParams) {
                // this.loading = true;
                // HttpService.getInstance()
                //     .get(`studentMarks/getByModule/${this.activeModule.id}?${queryParams}`)
                //     .then(resp => {
                //         this.loading = false;
                //         this.items = resp.items;
                //     });
            },
            formatDate(date) {
                if (date !== null && date !== undefined) {
                    return moment(date).format("DD.MM.YYYY");
                }
            },
        }
    }
</script>