<template>
    <v-app>
        <vue-headful title="eBSMU"/>

        <toolbar @toggleDrawer="drawerApp = !drawerApp"/>
        
        <navigation v-if="$route.name !== 'studentSuperAdminPreselect'" @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        
        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/student/common/Toolbar";
    import NavigationDrawer from "../../components/student/common/NavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            navigation: NavigationDrawer,
            Toolbar,
            SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
