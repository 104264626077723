<template>
    <v-container class="register-container" style="position: relative">
        <block-loader v-if="loading"/>
        <v-layout justify-center>
            <why-i-dont-see-surnames/>
        </v-layout>
        <v-layout justify-center>
            <v-flex xs12>
                
                <v-layout class="d-flex justify-center">
                    <v-flex xs12 md8 lg6>
                        <table class="marks-table elevation-3 mb-6 statistics-table">
                            <thead>
                            <tr>
                                <th width="100" class="cell-header font-weight-light"></th>
                                <th class="cell-header font-weight-light">
                                    ПІП
                                </th>
                                <th width="200" class="cell-header font-weight-light">
                                    Середній бал
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="(studentStatistic, index) in studentStatistics">
                                <td class="module-overview-cell-student">{{index + 1}}.</td>
                                <td class="module-overview-cell-student">
                                    {{`${studentStatistic.surname} ${studentStatistic.name} ${studentStatistic.patronymic}`}}
                                </td>
                                <td :class="getCellClass(studentStatistic.average)">
                                    {{studentStatistic.average ? studentStatistic.average.toFixed(2) : "-"}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </v-flex>
                </v-layout>

            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import BlockLoader from "../../../common/BlockLoader";
    import WhyIDontSeeSurnames from "../../../common/WhyIDontSeeSurnames";

    export default {
        components: {
            BlockLoader,
            WhyIDontSeeSurnames
        },
        data: () => ({
            studentStatistics: [],
            loading: false,
        }),
        mounted() {
            this.getData();
        },
        watch: {
            "$route.params.subject": function (newSubject, oldSubject) {
                if (newSubject !== undefined && newSubject !== oldSubject) {
                    this.getData();
                }
            }
        },
        methods: {
            getData(){
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentMarks/getGroupStatisticsBySubject/${this.$route.params.subject}`)
                    .then(resp => {
                        this.studentStatistics = resp;
                        this.loading = false;
                    });
            },
            getCellClass(markAverage) {
                return `module-overview-cell-student mark mark${Math.round(markAverage)}`;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cell-header {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        min-width: 25px;
        border-bottom: 1px solid #ccc;
    }
    .module-overview-cell-student {
        padding: 10px 10px;
        border-right: 1px solid #ccc;
        text-align: center;
    }
    .statistics-table {
        width: 100%;
    }
</style>