<template>
    <div class="mt-4">
        <v-expansion-panels v-model="expandable">
            <v-expansion-panel lazy>
                <v-expansion-panel-header>
                  <div><v-icon>format_list_numbered</v-icon> Перегляд інформації по модулях</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <student-module-overview/>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel lazy>
                <v-expansion-panel-header @click="onOpenGroupStat()">
                    <div><v-icon>sort</v-icon> Перегляд статистики в групі  </div> 
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <student-group-statistics/>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel lazy>
                <v-expansion-panel-header @click="onOpenFacultyStat()">
                    <div><v-icon>bar_chart</v-icon> Перегляд статистики на факультеті</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <student-faculty-statistics/>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
    import StudentModuleOverview from "./marksOverview/StudentModuleOverview";
    import StudentGroupStatistics from "./marksOverview/StudentGroupStatistics";
    import StudentFacultyStatistics from "./marksOverview/StudentFacultyStatistics";
    
    export default {
        components: {
            StudentModuleOverview,
            StudentGroupStatistics,
            StudentFacultyStatistics
        },
        props: {
            activeModule: {
                type: Object
            }
        },
        data() {
            return {
                expandable: 0,
            }
        },
        methods: {
            onOpenGroupStat() {
                this.$ga.event('student', 'viewGroupStat', 'subjectId', this.$route.params.subject);
            },
            onOpenFacultyStat() {
                this.$ga.event('student', 'viewFacultyStat', 'subjectId', this.$route.params.subject);
            }
        }
    }
</script>