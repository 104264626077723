<template>
    <div>
        <v-card :elevation="6" class="mb-2">
            <v-container grid-list-md fluid fill-height>
                <template v-if="selectedReworks.length === 0">
                    <v-layout style="min-height: 44px;" xs12 md12 align-center>
                        <div class="payment-text-placeholder grey--text">
                            Щоб обрати пропущенні заняття без поважної причини
                            <v-icon color="red darken-2">font_download</v-icon>
                            для оплати клікніть на них. Ви можете обрати одразу декілька.
                        </div>
                    </v-layout>
                </template>

                <template v-if="selectedReworks.length > 0">
                    <v-layout xs12 md3>
                        <form
                                @submit.prevent="onSubmit()"
                                ref="liqPayForm"
                                method="POST"
                                action="https://www.liqpay.ua/api/3/checkout"
                                accept-charset="utf-8">
                            <input
                                    type="hidden"
                                    name="data"
                                    :value="payment.liqPayData"/>
                            <input type="hidden" name="signature" :value="payment.liqPaySignature"/>
                            <v-btn :loading="paymentSendLoading"
                                   type="submit"
                                   color="primary"
                            >
                                Сплатити
                            </v-btn>
                        </form>
                    </v-layout>
                    <v-layout xs12 md4>
                        <span class="title">
                            <span class="font-weight-light">Обрано пропусків: </span> 
                            {{selectedReworks.length}}
                        </span>
                    </v-layout>
                    <v-layout xs12 md4>
                        <span class="title">
                            <span class="font-weight-light">Загальна вартість відпрацювання: </span>
                            <span>{{payment.liqPayAmmount}}</span> {{getCurrency()}}
                        </span>
                    </v-layout>
                </template>
            </v-container>
        </v-card>
        
        <div class="mt-4">
            <admission-types-legend/>
        </div>
        
        <absents-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @input="onInputGrid"
                @update="updateGrid"
                @addNewAttachment="onAddNewAttachment($event)"
                @getAttachment="onGetAttachment($event)"
        >
        </absents-grid>
        
        <v-dialog v-model="dialogUploadAttachment" max-width="600px">
            <upload-attachment
                    v-if="dialogUploadAttachment"
                    :item="dialogUploadItem"
                    @saved="onSavedAttachment"
                    @shouldClose="dialogUploadAttachment = false"
            ></upload-attachment>
        </v-dialog>

        <v-dialog v-model="dialogUploadAttachmentView" max-width="1000px">
            <show-attachment
                    v-if="dialogUploadAttachmentView"
                    :item="dialogUploadItemView"
                    @shouldClose="dialogUploadAttachmentView = false"
                    @modifyAttachment="onAddNewAttachment($event)"
            ></show-attachment>
        </v-dialog>
    </div>
</template>

<script>
    import AbsentsGrid from "./AbsentsGrid";
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import {getCurrency} from "../../../helpers/currencyHelper";
    import AdmissionTypesLegend from "../../common/rework-admissions/AdmissionTypesLegend";
    import UploadAttachment from "./UploadAttachment";
    import ShowAttachment from "./ShowAttachment";

    export default {
        components: {
            ShowAttachment,
            AbsentsGrid,
            AdmissionTypesLegend,
            UploadAttachment,
        },
        data: () => {
            return {
                dialogUploadAttachment: false,
                dialogUploadItem: null,

                dialogUploadAttachmentView: false,
                dialogUploadItemView: null,
                
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                
                payment: {
                    liqPayData: '',
                    liqPaySignature: '',
                    liqPayAmmount: 0,
                    liqPayOrderId: null,
                },

                paymentSendLoading: false,
                selectedReworks: [],

                getCurrency,
            }
        },
        methods: {
            onSubmit() {
                this.paymentSendLoading = true;

                HttpService.getInstance({}, {}, true)
                    .post("payment/getDataSignature", this.selectedReworks)
                    .then(resp => {
                        this.payment = {
                            liqPayData: resp.data,
                            liqPaySignature: resp.signature,
                            liqPayAmmount: resp.ammount,
                            liqPayOrderId: resp.orderId,
                        };
                    })
                    .then(() => {
                        this.$refs.liqPayForm.submit();
                    })
                    .catch(() => {
                        this.paymentSendLoading = false;
                    })
            },
            onInputGrid(items) {
                this.selectedReworks = items;

                this.payment.liqPayAmmount = items
                    .map(i => i.reworkPrice)
                    .reduce(
                        function add(a, b) {
                            return a + b;
                        },
                    0);
            },
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentRework?${queryParams}`)
                    .then(reworks => {
                        this.items = reworks.items;
                        this.total = reworks.total;
                        this.loading = false;
                    });
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            updateGrid() {
                this.getItems(objectQueryStringify(this.pagination));
            },
            onSavedAttachment() {
                this.dialogUploadAttachment = false;
                
                this.updateGrid();
            },
            onAddNewAttachment(item) {
                this.dialogUploadItem = item;
                this.dialogUploadAttachment = true;
                
                this.dialogUploadAttachmentView = false;
            },
            onGetAttachment(item) {
                this.dialogUploadItemView = item;
                this.dialogUploadAttachmentView = true;
            }
        },
    }
</script>