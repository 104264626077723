var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3 scrollable",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.pagination,"footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"order-id-wrapper"},[_vm._v(_vm._s(item.orderId))])]}},{key:"item.endDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.formatShortDate(item.endDate)))]}},{key:"item.paymentStatus",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getPaymentColor(item.paymentStatus),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getPaymentStatus(item.paymentStatus))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }