<template>
    <v-menu
            v-if="mark.value != null"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="220"
            offset-x
            offset-overflow
            transition="slide-x-transition"
            class="mark-value-menu"
    >
        <template v-slot:activator="{ on }">
            <div v-on="on" :class="`${getMarkClass(mark)} pa-1 px-2 mark-inner-subject`">

                <span class="cursor-pointer mark-value">
                   <mark-inner :mark="mark"></mark-inner>
                </span>
            </div>
        </template>
        <v-card>
            <v-card-text style="max-width: 266px;">
                <v-layout wrap>
                    <v-flex xs6>
                        <v-chip class="subtitle-1"
                                :color="getMarkChipColor(mark)"
                                text-color="white"
                        >
                            <mark-inner :mark="mark"></mark-inner>
                        </v-chip>
                    </v-flex>
                    <v-flex xs6 class="ects-points">
                        <span class="subtitle-1 font-weight-bold">ECTS: <span class="font-weight-light">{{this.ectsPoints}}</span></span>
                    </v-flex>
                </v-layout>
                <template>
                    <v-flex xs12>
                        <span class="subtitle-1 font-weight-bold">Дата заняття: </span>
                        {{formatShortDate(mark.classDate)}}
                    </v-flex>
                    <v-flex xs12>
                        <span class="subtitle-1 font-weight-bold">Дисципліна: </span>
                        {{mark.subject ? mark.subject.nameUk : ""}}
                    </v-flex>
                    <v-flex xs12>
                        <span class="subtitle-1 font-weight-bold">Тема: </span>
                        {{mark.topic ? mark.topic.nameUk : ""}}
                    </v-flex>
                    <v-flex xs12>
                        <span class="subtitle-1 font-weight-bold">Викладач: </span>
                        {{`${mark.professor.surname} ${mark.professor.name.charAt(0)}.
                        ${mark.professor.patronymic.charAt(0)}.`}}
                    </v-flex>
                </template>

                <template v-if="!loaded && loading">
                    <v-container align-center align-content-center grid-list-md fluid fill-height>
                        <v-layout row wrap justi>
                            <v-progress-circular
                                    :size="150"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </v-layout>
                    </v-container>
                </template>

            </v-card-text>
        </v-card>
    </v-menu>

</template>
<script>
    import {getMarkChipColor, getMarkClass} from "../../../helpers/markHelper";
    import {formatLongDate, formatShortDate} from "../../../helpers/dateHelper";
    import {topicMarkEditTypesEnum} from "../../../constants/topicMarkEditTypes";
    import MarkInner from "../../common/MarkInner";

    export default {
        data: () => ({
            menu: false,
            ectsPoints: 0,
            loaded: false,
            loading: false,
            getMarkChipColor,
            formatShortDate,
            formatLongDate,
            topicMarkEditTypesEnum,

            getMarkClass
        }),
        props: {
            mark: {
                type: Object,
                default: () => {
                    return {value: null}
                }
            },
            student: {
                type: Object,
            },
            module: {
                type: Object,
                default: () => {
                    return {
                        ects3: 0,
                        ects4: 0,
                        ects5: 0,
                    };
                }
            },

        },
        mounted() {
            this.getEctsValue();
        },
        updated() {
            this.getEctsValue();
        },
        components: {
            MarkInner
        },
        methods: {
            getEctsValue() {
                switch (this.mark.value) {
                    case 5:
                        this.ectsPoints = this.module.ects5;
                        break;
                    case 4:
                        this.ectsPoints = this.module.ects4;
                        break;
                    case 3:
                        this.ectsPoints = this.module.ects3;
                        break;
                    case 2:
                    case 0:
                    default:
                        this.ectsPoints = 0;
                        break;
                }
            },
        }
    }
</script>
<style lang="scss">
    .mark-inner-subject {
        font-size: 22px !important;
    }
</style>