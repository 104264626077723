var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-flex',{staticClass:"subtitle-1",attrs:{"xs12":""}},[_vm._v("Загальна кількість тем: "+_vm._s(_vm.activeModule.topicsAllCount)+" ")]),_c('v-flex',{staticClass:"subtitle-1",attrs:{"xs12":""}},[_vm._v("Кількість оцінювальних тем: "+_vm._s(_vm.activeModule.topicsEvaluableCount)+" ")])],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('div',{class:((_vm.getMarkClass({value: 5})) + " mark-student-cell-preview")},[_vm._v("5")]),_c('div',{staticClass:"d-flex subtitle-1 justify-center"},[_vm._v("ECTS: "+_vm._s(_vm.activeModule.ects5))])]),_c('v-flex',{attrs:{"xs4":""}},[_c('div',{class:((_vm.getMarkClass({value: 4})) + " mark-student-cell-preview")},[_vm._v("4")]),_c('div',{staticClass:"d-flex subtitle-1 justify-center"},[_vm._v("ECTS: "+_vm._s(_vm.activeModule.ects4))])]),_c('v-flex',{attrs:{"xs4":""}},[_c('div',{class:((_vm.getMarkClass({value: 3})) + " mark-student-cell-preview")},[_vm._v("3")]),_c('div',{staticClass:"d-flex subtitle-1 justify-center"},[_vm._v("ECTS: "+_vm._s(_vm.activeModule.ects3))])])],1)],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-3 mt-4 scrollable",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.pagination,"hide-default-footer":"","no-data-text":"Немає оцінок"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.topicName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(((item.orderNumber) + ". " + (item.nameUk))))]}},{key:"item.evaluationMode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEvaluationModeType(item.evaluationMode))+" ")]}},{key:"item.markDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((item.mark !== null) ? _vm.formatMarkDate(item.mark.classDate) : "")+" ")]}},{key:"item.academicHours",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.academicHours))]}},{key:"item.markValue",fn:function(ref){
var item = ref.item;
return [_c('div',{class:((_vm.getMarkClass(item.mark)) + " mark-student-cell")},[_c('mark-inner',{attrs:{"mark":item.mark}})],1)]}},{key:"item.markEcts",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.mark !== null ? item.mark.valueEcts : ""))]}},{key:"item.professor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mark !== null ? ((item.mark.professor.surname) + " " + (item.mark.professor.name.charAt(0)) + ". " + (item.mark.professor.patronymic.charAt(0)) + ".") : "")+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }