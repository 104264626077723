<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3 scrollable"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.orderId="{item}">
            <div class="order-id-wrapper">{{item.orderId}}</div>
        </template>
        <template v-slot:item.endDate="{item}">{{ formatShortDate(item.endDate) }}</template>
        <template v-slot:item.paymentStatus="{item}">
            <v-chip :color="getPaymentColor(item.paymentStatus)" text-color="white">
                {{getPaymentStatus(item.paymentStatus)}}
            </v-chip>
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    import paymentStatuses, {paymentStatusesEnum} from "../../../constants/paymentStatuses";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'OrderId', value: 'orderId', sortable: false},
                {text: 'Опис', value: 'description', sortable: false},
                {text: 'Сума', value: 'amount', sortable: true},
                {text: 'Грошова одиниця', value: 'currency', sortable: false},
                {text: 'Дата оплати', value: 'endDate', sortable: false},
                {text: 'Картка', value: 'senderCardMask', sortable: false},
                {text: 'Статус', value: 'paymentStatus', sortable: false},
            ],
            formatShortDate,
        }),
        methods: {
            getPaymentStatus(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.nameUk;
            },
            getPaymentColor(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.color;
            }
        }
    }
</script>
<style lang="scss">
    .order-id-wrapper {
        word-break: break-all;
    }
</style>