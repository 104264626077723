<template>
    <v-card class="card--flex-toolbar">
        <v-toolbar prominent :height="40">
            <v-toolbar-title class="subtitle-1 grey-darken-3--text">{{this.module.nameUk}}</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>

        <v-tabs
                v-model="activeSub"
                background-color="blue lighten-3"
                dark
                centered
                fixed-tabs
                class="tabs-wrapper"
                icons-and-text
                :height="50"
        >
            <v-tabs-slider class="tab-slider-top" color="amber darken-4"></v-tabs-slider>
            <v-tab
                    ripple
                    key="all_marks"
                    active-class="mark-type-active-tab"
            >
                <span class="hidden-sm-and-down">Практичні заняття</span>
                <v-icon>line_style</v-icon>
            </v-tab>
            <v-tab
                    v-if="module.hasLectures"
                    ripple
                    key="lectures"
                    active-class="mark-type-active-tab"
            >
                <span class="hidden-sm-and-down">Лекційні заняття</span>
                <v-icon>library_books</v-icon>
            </v-tab>
            <v-tab
                    v-if="module.hasIndividualWork"
                    ripple
                    key="individual_work"
                    active-class="mark-type-active-tab"
            >
                <span class="hidden-sm-and-down">Самостійна робота</span>
                <v-icon>person_add</v-icon>
            </v-tab>
            <v-tab
                    ripple
                    key="module"
                    active-class="mark-type-active-tab"
            >
                <span class="hidden-sm-and-down">{{mapFinalControlType(this.module.finalControlType)}}</span>
                <v-icon>event_note</v-icon>
            </v-tab>
            <v-tabs-items v-model="activeSub" touchless>
                <v-tab-item key="all_marks">
                    <student-marks-list :active-module="activeModule"/>
                </v-tab-item>
                <v-tab-item key="lectures" v-if="module.hasLectures">
                    <student-lecture-list :active-module="activeModule"/>
                </v-tab-item>
                <v-tab-item key="individual_work" v-if="module.hasIndividualWork">
                    <student-individual-work :active-module="activeModule"/>
                </v-tab-item>
                <v-tab-item key="module">
                    <student-module :activeModule="activeModule"/>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </v-card>
</template>
<script>
    import finalControlTypes from "../../../constants/admin/finalControlTypes";
    import StudentMarksList from "./StudentMarksList";
    import StudentLectureList from "./StudentLectureList";
    import StudentModule from "./StudentModule";
    import StudentIndividualWork from "./StudentIndividualWork";

    export default {
        components: {
            StudentMarksList,
            StudentLectureList,
            StudentModule,
            StudentIndividualWork,
        },
        data: () => ({
            modules: [],
            activeSub: null,
        }),
        props: {
            module: {
                type: Object
            },
            activeModule: {
                type: Object,
            }
        },
        methods: {
            mapFinalControlType(finalControlType) {
                return finalControlTypes.find(f => f.typeEnum === finalControlType).nameConst;
            }
        },
    }
</script>